import { useRuntimeConfig } from '#imports'
import * as Sentry from '@sentry/nuxt'

const runtimeConfig = useRuntimeConfig()
const sentryDsn = runtimeConfig.public.sentryDsn
let sentryTracesSampleRate: number | undefined = undefined
if (runtimeConfig.public.sentryTracesSampleRate) {
  sentryTracesSampleRate = parseFloat(runtimeConfig.public.sentryTracesSampleRate) || undefined
}
if (sentryDsn) {
  Sentry.init({
    dsn: sentryDsn,
    environment: runtimeConfig.public.sentryEnvironment,
    tracesSampleRate: sentryTracesSampleRate,
    profilesSampleRate: sentryTracesSampleRate,
    integrations: [Sentry.browserProfilingIntegration()],
  })
} else {
  console.error('No Sentry DSN provided')
}
